export const AVAST_AVG_SUPPORT_PHONE_MAP: StringMap = {
  GB: '448000321430',
  DE: '498001013815',
  FR: '33805542423',
  AU: '611800417836',
  NZ: '64800461302',
  BR: '',
  MX: '528002690181',
  IT: '39800147648',
  ES: '34900031433',
  NL: '318002929204',
  PL: '48800013019',
  CH: '41800000521',
  BE: '3280089479',
  SE: '4620980704',
  IE: '3531800900670',
};
export const PARTNER_SUPPORT_URL_MAP: StringMap = {
  avast_intl: 'https://support.avast.com/en-us/article/avast-secure-identity-faq/',
  avg_intl:
    'https://support.avg.com/SupportArticleView?l=en&urlName=avg-secure-identity-faq&q=secure+identity&supportType=home',
};
