import {useQuery} from '@tanstack/react-query';
import {fetchRest} from '../helpers/fetchRest';
import utils from '../helpers/utils';

/**
 * Fetch user scam verification cases through member-api.
 * @return {Object} scam verification cases data, various response status(s),
 * and customize object for handling scam verification cases and case details screen(s).
 */

export const useScamVerificationCases = () => {
  const url = utils.getScamVerificationCasesPath();
  return useQuery(['scamProtect'], () =>
    fetchRest(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
  );
};
