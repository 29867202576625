export async function checkUseCreditMockEndpoints(accountGuid: $TSFixMe) {
  if (!accountGuid) {
    console.error('Error fetching user preferences: accountGuid is missing');
    return false;
  }
  try {
    const userPreferenceResponse = await fetch(
      `${window.REACT_APP_MOCK_CREDIT_TOOL_BASE_URL}/creditMockTool/userPreferences/${accountGuid}`
    );

    const userPreferenceResponseJSON = await userPreferenceResponse.json();
    const useCreditMockEndpoints = userPreferenceResponseJSON?.useMockEndpoints || false;

    return useCreditMockEndpoints;
  } catch (error) {
    console.error('Error fetching user preferences:', error);
    return false;
  }
}

export async function getMockCreditScoreLatest(accountGuid: string) {
  try {
    const creditScoreLatestMockResponse = await fetch(
      `${window.REACT_APP_MOCK_CREDIT_TOOL_BASE_URL}/creditMockTool/creditScore/latest?guid=${accountGuid}`
    );

    return (await creditScoreLatestMockResponse.json()) || null;
  } catch (error) {
    console.error('Error fetching mock credit score latest:', error);
    return null;
  }
}

export async function getMockCreditScoreHistory(accountGuid: string) {
  try {
    const creditScoreHistoryMockResponse = await fetch(
      `${window.REACT_APP_MOCK_CREDIT_TOOL_BASE_URL}/creditMockTool/creditScore/history?guid=${accountGuid}&&scoreType=US_EFX`
    );
    return (await creditScoreHistoryMockResponse.json()) || null;
  } catch (error) {
    console.error('Error fetching mock credit score history:', error);
    return null;
  }
}

export async function getMockCreditReportLatest(accountGuid: string) {
  try {
    const creditReportLatestMockResponse = await fetch(
      `${window.REACT_APP_MOCK_CREDIT_TOOL_BASE_URL}/creditMockTool/creditReport/latest?guid=${accountGuid}`
    );
    return (await creditReportLatestMockResponse.json()) || null;
  } catch (error) {
    console.error('Error fetching mock credit report latest:', error);
    return null;
  }
}

export async function getMockCreditReportById(accountGuid: string, previousCreditReportId: string) {
  try {
    const creditReportResponse = await fetch(
      `${window.REACT_APP_MOCK_CREDIT_TOOL_BASE_URL}/creditMockTool/creditReport/${previousCreditReportId}?guid=${accountGuid}`
    );

    return (await creditReportResponse.json()) || null;
  } catch (error) {
    console.error('Error fetching mock credit report by id:', error);
    return null;
  }
}

export async function getMockCreditReportLatestByType(accountGuid: string, reportType: string) {
  try {
    const creditReportMockLatestByTypeResponse = await fetch(
      `${window.REACT_APP_MOCK_CREDIT_TOOL_BASE_URL}/creditMockTool/creditReport/latest?guid=${accountGuid}&reportType=${reportType}`
    );
    return (await creditReportMockLatestByTypeResponse.json()).reportData || null;
  } catch (error) {
    console.error('Error fetching mock credit report latest by type:', error);
    return null;
  }
}

export async function getMockCreditReportHistory(accountGuid: string) {
  try {
    const creditReportHistoryMockResponse = await fetch(
      `${window.REACT_APP_MOCK_CREDIT_TOOL_BASE_URL}/creditMockTool/creditReport?guid=${accountGuid}`
    );
    return (await creditReportHistoryMockResponse.json()) || null;
  } catch (error) {
    console.error('Error fetching mock credit report history:', error);
    return null;
  }
}

export async function getMockCreditNextPullDate(accountGuid: string) {
  try {
    const response = await fetch(
      `${window.REACT_APP_MOCK_CREDIT_TOOL_BASE_URL}/creditMockTool/nextPullInfo?guid=${accountGuid}`
    );
    const result = await response.json();
    return result || null;
  } catch (error) {
    console.error('Error fetching mock credit next pull date:', error);
    return null;
  }
}

export async function getAccountGuidAndMockEndpointStatus(currentState: {
  auth: {user: {primaryMember: {accountGuid: string}}};
}) {
  const accountGuid = currentState.auth?.user?.primaryMember?.accountGuid || '';

  if (!window.REACT_APP_ENABLE_MOCK_CREDIT_TOOL) {
    return {accountGuid, useCreditMockEndpoints: false};
  }

  const useCreditMockEndpoints = await checkUseCreditMockEndpoints(accountGuid);

  return {accountGuid, useCreditMockEndpoints};
}
